<template>
  <section :class="$style.article">
    <el-form
      :class="$style.form"
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="12.5rem"
    >
      <el-form-item label="Название" prop="name">
        <el-input
          v-model="form.name"
          @focus="!form.slug ? handleNameInput(form.name) : ''"
        ></el-input>
      </el-form-item>
      <el-form-item label="Название ссылки" prop="slug">
        <el-input v-model="form.slug" clearable></el-input>
      </el-form-item>
      <el-form-item label="Фото товара" prop="images">
        <Uploader
          isContentCore
          :files="form.images || []"
          @upload="uploadImages"
          :class="$style.uploader"
        />
      </el-form-item>
      <el-form-item label="Дата начала" prop="startDate">
        <el-date-picker
          v-model="form.startDate"
          type="date"
          placeholder="Выберите день"
        />
      </el-form-item>
      <el-form-item label="Дата окончания" prop="expireDate">
        <el-date-picker
          v-model="form.expireDate"
          type="date"
          placeholder="Выберите день"
        />
      </el-form-item>
      <el-form-item label="Текст кнопки" prop="caption">
        <el-input v-model="form.redirectAction.caption"></el-input>
      </el-form-item>
      <el-form-item label="Ссылка кнопки" prop="linkTo">
        <el-input v-model="form.redirectAction.linkTo"></el-input>
      </el-form-item>
      <el-form-item label="Тип кнопки" prop="type">
        <el-input v-model="form.redirectAction.type"></el-input>
      </el-form-item>
      <el-form-item label="Дата публикации">
        <el-date-picker
          v-model="form.publishedAt"
          type="date"
          :class="$style.date"
          placeholder="Выберите дату"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="Отображать на сайте">
        <el-checkbox v-model="form.isActive" />
      </el-form-item>
      <el-form-item label="Описание" prop="content" />
      <TextEditor :value.sync="form.content" />
      <SeoBlock
        style="margin-top: 2rem"
        :title.sync="form.seo.title"
        :description.sync="form.seo.description"
        :og-title.sync="form.seo.ogTitle"
        :og-description.sync="form.seo.ogDescription"
        :og-image.sync="form.seo.ogImage"
        :class="$style.seo"
      />
      <el-button type="primary" @click="submitForm('form')">
        Сохранить
      </el-button>
    </el-form>
  </section>
</template>

<script>
import delivery from '@/delivery'
import { transliterate } from '@/helpers/slug'
import regexp from '@/helpers/regexp.js'
import SeoBlock from '@/components/moleculs/SEO.vue'
import Uploader from '@/components/moleculs/AddwineUploader.vue'
import TextEditor from '@/components/atoms/TextEditor.vue'
import notifications from '@/mixins/notifications'

export default {
  components: {
    TextEditor,
    SeoBlock,
    Uploader,
  },
  mixins: [notifications],
  data() {
    return {
      form: {
        images: [],
        name: '',
        slug: '',
        content: '',
        isActive: false,
        startDate: '',
        expireDate: '',
        publishedAt: '',
        redirectAction: {
          linkTo: '',
          caption: '',
          type: '',
        },
        seo: {
          title: '',
          description: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: '',
        },
      },
      rules: {
        images: [
          {
            type: 'array',
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        slug: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
          {
            pattern: regexp.slug,
            message: 'Введен недопустимый символ',
            trigger: 'change',
          },
        ],
        startDate: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        expireDate: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        content: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        seo: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogTitle: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogDescription: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogImage: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'change',
            },
          ],
        },
      },
    }
  },
  async created() {
    await this.getPromoArticle()
  },
  methods: {
    async getPromoArticle() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ContentServiceCore.PromoArticlesActions.getById(
          this.$route.params.id,
        )
      if (error) return

      this.form = {
        ...value,
        expireDate: new Date(value.expireDate),
        startDate: new Date(value.startDate),
      }
      if (this.form.redirectAction === null) {
        this.form.redirectAction = {
          ...this.form.redirectAction,
          type: '',
          caption: '',
          linkTo: '',
        }
      }
      loading.close()
    },
    handleNameInput(value) {
      this.form.slug = transliterate(value)
    },
    uploadImages(images) {
      this.form.images = images
    },
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })

          const data = { ...this.form }
          if (
            !data.redirectAction.type ||
            !data.redirectAction.linkTo ||
            !data.redirectAction.caption
          ) {
            data.redirectAction = null
          }

          if (this.form.startDate > this.form.expireDate) {
            loading.close()
            this.showNotification(
              'Дата начала акции не может быть позже, чем дата окончания ',
              'error',
            )
            return
          }

          const result =
            await delivery.ContentServiceCore.PromoArticlesActions.update(
              this.$route.params.id,
              data,
            )
          if (result.error) {
            this.showNotification('Ошибка создания акции', 'error')
          } else {
            this.showNotification('Акция успешно создана', 'success')
            this.$router.push('/addwine/promotions/promo-articles')
          }
          loading.close()
        }
      })
    },
  },
}
</script>

<style lang="scss" module>
.article {
  padding: 1rem;
  .form {
    & > div > label {
      text-align: left;
    }
    .uploader {
      justify-content: flex-start;
      img {
        margin-left: 12rem;
      }
    }
    .date {
      width: 100%;
    }
  }
}
</style>
